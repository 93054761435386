import refreshToken from '@/plugins/refresh-token'
import { api } from './main'
import { api as api2 } from './mainNode'

const packageList = async () => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'packageshop/list',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get shop package list  err : ', err)

      return []
    })

  return response
}
const packageShop = async () => {
  const response = await api2
    .get({
      path: 'packageshop',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get shop package list  err : ', err)

      return []
    })

  return response
}
const packageRenew = async body => {
  const response = await api2
    .post({
      path: 'paysolutions/newChangePackage',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('renew shop package  err : ', err)

      return {}
    })

  return response
}
export default { packageList, packageShop, packageRenew }
