<template>
  <div>
    <v-card-title>
      {{ $t('package_rate') }}<span class="error--text h5 ma-2">{{ $t('this_price_total_vat') }}</span>
    </v-card-title>
    <!-- <v-row class='px-5'>
      <v-col v-for='(item, index) in packageList' :key='index' cols='12' md='3'>
        <v-card>
          <v-card-title class='d-flex justify-content-between'>
            <span class='pe-1'>{{ item.packageshop_name }}</span>
            <span>
              <span class='error--text'> {{ item.total_vat | formatPrice }}</span>
              {{ $t('bath') }}/{{ $t('year') }}</span
            >
          </v-card-title>
          <v-card-text>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('system_online') }}
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('can_be_used') }} {{ +item.packageshop_useshop + 1 }}
              {{ $t('branch') }}
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('manager') }} {{ item.packageshop_usemanager }}
              User
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('doctor') }} {{ item.packageshop_usedoctor }}
              User
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('staff') }} {{ item.packageshop_useuser }}
              User
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('free_card_reader') }}
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              {{ $t('support_face_chart_system') }}
            </div>
            <v-divider class='my-2'></v-divider>
            <div class='font-weight-semibold text-md'>
              <v-icon left color='success'>
                {{ checkCircle }}
              </v-icon>
              Support | Update (Free)
            </div>
            <v-divider class='my-2'></v-divider>
          </v-card-text>
          <v-card-actions>
            <p v-if='index == 0' class='ribbon elevation-4'>
              <span class='text'>{{ $t('current_package') }} </span>
            </p>
            <v-spacer></v-spacer>
            <v-btn color='primary' outlined :loading='loading' :disabled='loading'
                   @click='renew(item.packageshop_id)'>
              <v-icon left>
                {{ cartIcons }}
              </v-icon>
              {{ $t('order_buy') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->
    <a class="d-flex px-4 py-2 align-items-center" href="https://line.me/R/ti/p/@gbc9405c" target="_blank">
      <v-img src="@/assets/images/line.png" max-width="50"></v-img>
      <span class="pt-3">{{ $t('contact_line') }}</span>
    </a>
    <v-dialog v-model="isShowMsessage" max-width="550">
      <v-card>
        <v-card-title>
          <div align="center">
            <span class="success--text">
              {{ $t('messageChangePackage') }}
            </span>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="isShowMsessage = false">
            {{ $t('close') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCheckCircle, mdiCartVariant, mdiCloudUpload, mdiCheckCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { formatPrice } from '@/plugins/filters'
import { required } from '@/@core/utils/validation'
import packages from '@/api/packages'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  filters: {
    formatPrice,
  },

  setup() {
    const moment = require('moment')
    const loading = ref(false)
    const menu1 = ref(false)
    const menu2 = ref(false)
    const select_bank = ref('2')
    const dialog = ref(false)
    const packageList = ref([])
    const packageSelect = ref('')
    const paymentType = ref('1')
    const bank_list = ref([])
    const blob_image = ref('')
    const payment_date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const payment_time = ref('')
    const shop = ref('')
    const payment_evidence = ref('')
    const isShowMsessage = ref(false)
    const pathAmount = ref(0)
    const statusActive = ref(0)
    const alertMessage = ref('')

    packages.packageList().then(res => {
      packageList.value = []
      let tmpPackage = {}
      res.forEach(item => {
        if (item.packageshop_active_id == '1') {
          tmpPackage = {
            ...item,
            total_vat: vat(item.packageshop_cost),
            read_card: i18n.t('free_card_reader'),
          }
        } else {
          packageList.value.push({
            ...item,
            total_vat: vat(item.packageshop_cost),
            read_card: i18n.t('free_card_reader'),
          })
        }
      })
      packageList.value.unshift(tmpPackage)
      console.log(' tmpPackage', packageList.value)
    })

    const vat = packageshop_cost => +packageshop_cost + packageshop_cost * 0.07

    const renew = id => {
      loading.value = true
      packages.packageRenew({ packageshop_id: id }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        console.log('res : ', res)
        if (res.code == 10000) {
          window.location.href = res.data.url
          loading.value = false
        } else {
          alertMessage.value = res.message
          isShowMsessage.value = true
          loading.value = false
        }
        loading.value = false
      })
    }

    return {
      checkCircle: mdiCheckCircle,
      cartIcons: mdiCartVariant,
      uploadIcons: mdiCloudUpload,
      loading,
      menu1,
      menu2,
      isShowMsessage,
      select_bank,
      alertMessage,
      dialog,
      packageList,
      packageSelect,
      paymentType,
      bank_list,
      blob_image,
      payment_date,
      payment_time,
      shop,
      payment_evidence,
      pathAmount,
      statusActive,
      required,
      renew,
      icons: {
        mdiCheckCircleOutline,
      },
    }
  },
}
</script>

<style lang='scss' scoped>
.ribbon {
  font-size: 15px;
  position: relative;
  display: inline-block;
  color: #ffffff;
  margin-left: -30px;
  text-align: center;
}

.text {
  display: inline-block;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
  min-width: 10em;
  line-height: 1.2em;
  background: #ec407a;
  position: relative;
}

.ribbon:after,
.ribbon:before,
.text:before,
.text:after,
.bold:before {
  content: '';
  position: absolute;
  color: #ffffff;
  border-style: solid;
}

.ribbon:before {
  top: 0.3em;
  left: 0.2em;
  color: #ffffff;
  width: 100%;
  height: 100%;
  border: none;
  background: #ebeced;
  z-index: -2;
}

.text:before {
  bottom: 100%;
  left: 0;
  color: #ffffff;
  border-width: 0.5em 0.7em 0 0;
  border-color: transparent #f48fb1 transparent transparent;
}

.text:after {
  top: 100%;
  color: #ffffff;
  right: 0;
  border-width: 0.5em 2em 0 0;
  border-color: #f48fb1 transparent transparent transparent;
}
</style>
